
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles"
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { ResponsiveContainer, Label } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment';
import { useTheme } from "@material-ui/core/styles";
import useCharts from "../../hooks/useCharts";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: "none",

    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",

    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",

    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
    typography: {
        fontSize: "121%"
    },
}))

const DashTicketsEvolutionByPeriod = (props) => {
    const { startDate, endDate, period } = props;
    const theme = useTheme();
    const classes = useStyles()
    const [statistics, setStatistics] = useState([]);
    const { findTicketsByDate } = useCharts();

    useEffect(() => {

        const fetchStats = async (startDate, endDate, period) => {
            try {
                const companyId = localStorage.getItem("companyId");
                if (period > 0) {
                    startDate = moment().subtract(period, "days").format("YYYY-MM-DD");
                    endDate = moment().format("YYYY-MM-DD");
                }

                if (startDate) {
                    const data = await findTicketsByDate({
                        startDate, endDate, companyId
                    });
                    setStatistics(data);
                }
            } catch (err) {
                toastError(err);
            }
        };
        fetchStats(startDate, endDate, period);

    }, [startDate, endDate, period]);


    return (
        <>
            <Typography component="h3" variant="h6" color="primary" paragraph className={classes.typography}>
                Evolução atendimentos  </Typography>
            <ResponsiveContainer>
                <BarChart
                    data={statistics}
                    barSize={40}
                    width={730}
                    height={250}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke={theme.palette.text.secondary} />
                    <YAxis

                        type="number"
                        allowDecimals={false}
                        stroke={theme.palette.text.secondary}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
                        >
                            Atendimentos
                        </Label>
                    </YAxis>

                    <Tooltip

                    />

                    <Bar dataKey="value" fill={theme.palette.primary.main} />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}

export default DashTicketsEvolutionByPeriod;