import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import Title from '../../pages/Dashboard/Title';
import useCharts from '../../hooks/useCharts';
import { Tooltip } from '@material-ui/core';
import moment from "moment";


const TicketsGroupByChannelChart = (props) => {
    const { startDate, endDate, period } = props;
    const [items, setItems] = useState([]);
    const { findTicketsGroupByChannels } = useCharts();

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index , value}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={11}>
                {`${(percent * 100).toFixed(0)}% (${value})`}
            </text>
        );
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchData = async (startDate, endDate, period) => {
                try {
                    const companyId = localStorage.getItem("companyId");
                    if (period > 0) {
                        startDate = moment().subtract(period, "days").format("YYYY-MM-DD");
                        endDate = moment().format("YYYY-MM-DD");
                    }

                    if (startDate) {
                        const data = await findTicketsGroupByChannels    ({
                            startDate, endDate, companyId
                        });

                        setItems(data);
                    }
                } catch (e) {
                    console.log("🚀 Console Log : e", e);
                }
            }
            fetchData(startDate, endDate, period);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [startDate, endDate, period]);

    return (
        <>
            <Title>Atendimento por Conexão</Title>
            <ResponsiveContainer>
                <PieChart width={400} height={400}>
                    <Legend iconSize={10}  verticalAlign="top" align="left" />
                    <Tooltip />
                    <Pie
                        data={items}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                    >
                        {items.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </>
    );
};

export default TicketsGroupByChannelChart;