
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles"
import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,


    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 340,
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",

    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
    typography: {
        fontSize: "121%"
    },
    table: {
        minWidth: 650,
    },
}))

const DashTicketsPerUsersDetail = (props) => {
    const classes = useStyles()
    const { startDate, endDate, period } = props;
    const [statistics, setStatistics] = useState([]);


    /*let rows = [
        createData('Não Informado', 0, 10, 10, 20),
        createData('Jorge Eduardo', 2, 20, 30, 52),
        
    ];*/

    function createData(name, closed, pending, open, total) {
        return { name, closed, pending, open, total };
    }

    useEffect(() => {
        const fetchStats = async (startDate, endDate, period) => {
            try {
                const companyId = localStorage.getItem("companyId");
                if (period > 0) {
                    startDate = moment().subtract(period, "days").format("YYYY-MM-DD");
                    endDate = moment().format("YYYY-MM-DD");
                }

                if (startDate) {
                    const { data } = await api.get("/statistics-tickets-per-users-detail", {
                        params: {
                            startDate,
                            endDate,
                            companyId
                        }
                    });


                    setStatistics(data);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchStats(startDate, endDate, period);

    }, [startDate, endDate, period]);


    return (
        < >
           

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Usuário</TableCell>
                            <TableCell align="right">Resolvidos</TableCell>
                            <TableCell align="right">Aguardando</TableCell>
                            <TableCell align="right">Em Atendimento</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statistics.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.qtd_resolvidos}</TableCell>
                                <TableCell align="right">{row.qtd_pendentes}</TableCell>
                                <TableCell align="right">{row.qtd_em_atendimento}</TableCell>
                                <TableCell align="right">{row.qtd_resolvidos + row.qtd_pendentes + row.qtd_em_atendimento}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DashTicketsPerUsersDetail;